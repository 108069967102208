import {lazy} from 'react'
const Lising = lazy(() => import('modules/shartnomalar/Lising'));
const LizingContract = lazy(() => import('modules/shartnomalar/Lising/container/lizingContract'));
const PrivateReport = lazy(() => import('modules/shartnomalar/Lising/components/technics-list/payment-report/paymentReport'));
const TechnicsOrders = lazy(() => import('modules/shartnomalar/Lising/components/technics-list/technics-orders'));
// const AgrotechnicalMeasures = lazy(()=> import('modules/Agrotexnik tadbirlar'))
// const CropPlacement = lazy(()=> import('modules/CropPlacement'))
// const Moliyalashtirish = lazy(()=> import('modules/Moliyalashtirish'))
// const SupplyServices = lazy(()=> import('modules/Taminot-xizmat'))
// const Uchaskalar = lazy(()=> import('modules/yer uchaskalari'))
// const HarvestReception = lazy(()=> import('modules/Hosil qabuli'))
// const FarmerWorkplace = lazy(()=> import('modules/Fermer ish urni'))
// const Meyorlar = lazy(()=> import('modules/Meyorlar'))
// const Users = lazy(()=> import('modules/Foydalanuvchilar'))
const AgreemenSign = lazy(()=> import('modules/shartnomalar/Lising/components/warranty-agreement/components/agreements/container'))
const NotFound = lazy(()=> import('components/not-found'))
const WarrantyAgreements = lazy(()=> import('modules/shartnomalar/Lising/container/warrantyAgreement'))
const WarrantyInfoTable = lazy(()=> import('modules/shartnomalar/Lising/components/warranty-agreement/components/kafillik_info'))
const Acts = lazy(()=> import('modules/shartnomalar/Lising/components/acts/container'))
const LisingActsSign = lazy(()=> import('modules/shartnomalar/Lising/components/acts/components/acts-to-sign'))
const InsuranceContract = lazy(()=> import('modules/shartnomalar/Lising/container/insuranceContract'))
const Apply = lazy(()=> import('modules/shartnomalar/Lising/components/insurance-contract/components/apply/container'))
const AddGuarantor = lazy(()=> import('modules/shartnomalar/Lising/components/warranty-agreement/components/add-guarantor/container'))
const Insure = lazy(()=> import('modules/shartnomalar/Lising/components/insurance-contract/components/to-insure/container'))
const LisingOrdersSign = lazy(()=> import('modules/shartnomalar/Lising/components/orders/orders-tosign'))
const LeasingContractsToSign = lazy(()=> import('modules/shartnomalar/Lising/components/leasing_contract/leasingContracts-tosign/container'))
const WarrantyAgreementsToSign = lazy(()=> import('modules/shartnomalar/Lising/components/warranty-agreement/components/warrantyAgreements-tosign'))
const SignOrderInfo = lazy(()=> import('modules/shartnomalar/Lising/components/orders/components/sign-order-info'))


export const routes = [
  {
    path: '/shartnomalar/lizing/technics-list',
    private: false,
    component: Lising,
  },
  {
    path: '/shartnomalar/lizing/leasing-contract',
    private: false,
    component: LizingContract,
  },
  {
    path: '/shartnomalar/lizing/leasing-contract/leasing-contracts-to-sign',
    private: false,
    component: LeasingContractsToSign, //Lizing shartnoma
  },
  {
    path: '/shartnomalar/lizing/warranty-contract',
    private: false,
    component: WarrantyAgreements,
  },
  {
    path: '/shartnomalar/lizing/acts',
    private: false,
    component: Acts,
  },
  {
    path: '/shartnomalar/lizing/acts/leasing-acts-tosign',
    private: false,
    component: LisingActsSign, //Dalolatnoma done
  },
  {
    path: '/shartnomalar/lizing/warranty-contract/:id',
    private: false,
    component: WarrantyInfoTable,
  },
  {
    path: '/shartnomalar/lizing/warranty-contract/add-guarantor-technique/:id',
    private: false,
    component: AddGuarantor,
  },
  {
    path: '/warrantyagreements-tosign',
    private: false,
    component: WarrantyAgreementsToSign, //Kafillik shartnoma
  },
  {
    path: '/shartnomalar/lizing/payment_graph/:id',
    private: true,
    component: PrivateReport,
  },
  {
    path: '/shartnomalar/lizing/technics/orders/:id',
    private: true,
    component: TechnicsOrders,
  },
  {
    path: '/shartnomalar/lizing/orders/leasing-orders-tosign', //Buyurtmalar
    private: false,
    component: LisingOrdersSign,
  },
  {
    path: '/shartnomalar/lizing/orders/sign-order/:id', //Buyurtmalar
    private: false,
    component: SignOrderInfo,
  },
  // {
  //   path: '/land-areas',
  //   private: true,
  //   component: Uchaskalar,
  // },
  // {
  //   path: '/placement-of-crops',
  //   private: true,
  //   component: CropPlacement,
  // },
  // {
  //   path: '/moliyalashtirish',
  //   private: true,
  //   component: Moliyalashtirish,
  // },
  // {
  //   path: '/taminot-xizmat',
  //   private: true,
  //   component: SupplyServices,
  // },
  // {
  //   path: '/agrotexnik-tadbirlar',
  //   private: true,
  //   component: AgrotechnicalMeasures,
  // },
  // {
  //   path: '/hosil-qabuli',
  //   private: true,
  //   component: HarvestReception,
  // },
  // {
  //   path: '/fermer-ish-urni',
  //   private: true,
  //   component: FarmerWorkplace,
  // },
  // {
  //   path: '/meyorlar',
  //   private: true,
  //   component: Meyorlar,
  // },
  // {
  //   path: '/users',
  //   private: true,
  //   component: Users,
  // },
  {
    path: '/shartnomalar/lizing/agreements-sign',
    private: true,
    component: AgreemenSign,
  },
  {
    path: '/shartnomalar/lizing/insurance-contract',
    private: false,
    component: InsuranceContract,
  },
  {
    path: '/shartnomalar/lizing/insurance-contract/petitions/:id',
    private: false,
    component: Apply,
  },
  {
    path: '/shartnomalar/lizing/insurance-contract/to-insure/:id',
    private: false,
    component: Insure,
  },

  {
    path: '*',
    private: true,
    component: NotFound,
  },
];
