import React, { useState, Fragment, useEffect } from 'react';
import './sidebar.css'
import { motion, AnimatePresence } from 'framer-motion'
import AgroLogo from '../../assets/images/auth/AgroPlatformaLogoAuth.png'
import { NavLink, useHistory } from 'react-router-dom'
import { sidebar_Name } from './components/sidebar.path'
import { FaBars } from 'react-icons/fa'
import { SidebarDropMenu } from './components/SidebarDropMenu';
import LogoutIcon from "../../assets/icons/sidebar-icons/Logout_icon";
import TechnixsHelp from './components/technixsHelp';
import { useSelector, useDispatch } from 'react-redux';
import { editSidebarIsOpen } from 'redux/reducer/navTitleReduser';

function Sidebar() {
    const history = useHistory()
    const dispatch = useDispatch()
    const [isOpenMenu, setIsOpenMenu] = useState(false)
    const [isOpen, setIsOpen] = useState(true);
    const [isMobile, setIsMobile] = useState(false);
    const sidebarIsOpen = useSelector(state => state.sidebarIsOpen.sidebarIsOpen)
    const toggle = () => {
        setIsOpen(!isOpen);
        setIsOpenMenu(false)
        if (isMobile) dispatch(editSidebarIsOpen(false))
    }
    function SidebarClick() {
        setIsOpenMenu(false)
        if(isMobile){
            toggle()
        }
    }
    const showAnimation = {
        hidden: {
            width: 0,
            opacity: 0,
            transition: {
                duration: 0.5,
            },
        },
        show: {
            width: "100%",
            opacity: 1,
            transition: {
                duration: 0.5,
            },
        }
    }

    // function resizeListener() {
    //     if (window.innerWidth <= 576) {
            
    //         setIsMobile(true)
    //     } else {
    //         setIsOpen(true)
    //         setIsMobile(false)}
    // }


    // useEffect(() => {
    //     resizeListener()
    //     window.addEventListener("resize", resizeListener);

    //     return () => window.removeEventListener("resize", resizeListener);

    // }, [])
    useEffect(() => {
        if (sidebarIsOpen) {
            setIsOpen(prev => !prev);
            setIsOpenMenu(false)
        }
    }, [sidebarIsOpen])
    useEffect(() => {
        if (!isMobile) {
            setIsOpen(true);
            setIsOpenMenu(false)
        }
        if (isMobile) {
            dispatch(editSidebarIsOpen(false))
        }
    }, [isMobile])
    return (
        <motion.div className='Sidebar'
            style={{
                width: (isOpen && isMobile) ? "100%" : isOpen ? "325px" : "70px",
                display: (!sidebarIsOpen && isMobile) ? 'none' : 'inline-block',
                position: isMobile ? 'fixed' : 'static',
                top: 0,
                left: 0,
                zIndex: 10,
            }}
        >
            <div className='LogoCard' style={{ justifyContent: !isOpen && "center" }}>
                {
                    <img className='AgroLogo' style={{ width: isOpen ? '134px' : 0, transition: isOpen && 'all 0.3s' }}
                        src={AgroLogo} alt="Agro Logo" onClick={() => history.push('/')} />
                }
                <span onClick={toggle} style={{ cursor: 'pointer' }}> <FaBars style={{ color: "#fff", fontSize: '20px' }} /></span>
            </div>
            <section className='routes'>
                {
                    sidebar_Name?.map((route, index) => {
                        if (route?.drop) {
                            return (
                                <Fragment key={index}>
                                    <SidebarDropMenu isOpen={isOpen} showAnimation={showAnimation} route={route}
                                        dropMenu={route.drop}
                                        isOpenMenu={isOpenMenu}
                                        setIsOpenMenu={setIsOpenMenu}
                                        setIsOpen={setIsOpen}
                                        toggle={toggle}
                                    />
                                </Fragment>
                            )
                        }
                        return (
                            <NavLink to={route.path} key={index} activeClassName="actives" onClick={SidebarClick} className="sidebar-link" >
                                <div className="icon">{route?.icon}</div>
                                <AnimatePresence>
                                    {isOpen && <motion.div
                                        variants={showAnimation}
                                        initial="hidden"
                                        animate="show"
                                        exit={"hidden"}
                                        className="link_text"
                                    >
                                        {route?.name}
                                    </motion.div>}
                                </AnimatePresence>
                            </NavLink>
                        )
                    })
                }
                <div className="sidebar-link">
                    <div className='logout_icon'><LogoutIcon /> </div>
                    {isOpen && <div className='link_text'>Chiqish</div>}
                </div>
            </section>
            <TechnixsHelp isOpen={isOpen} />
        </motion.div>
    );
}

export default Sidebar;