
import React from "react"

export default function Yer_u_icon() {
    return (<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M11.957 5.90648C12.4021 5.19353 12.7847 4.45482 13.2767 3.82777C14.7134 2.01534 16.5484 1.08765 18.727 1.00175C19.2892 0.975981 19.5078 1.23367 19.4922 1.85213C19.3829 5.95802 16.5718 9.34237 12.8472 9.83198C12.7847 9.84057 12.7223 9.84916 12.6129 9.86634C12.6051 10.0124 12.5895 10.167 12.5895 10.3216C12.5895 11.4211 12.5895 12.5292 12.5895 13.6286C12.5895 13.7317 12.5895 13.8262 12.5895 13.9293C12.5739 14.4275 12.3474 14.7195 11.9883 14.7109C11.6291 14.7024 11.4182 14.4103 11.4182 13.9035C11.4104 12.7267 11.4182 11.5585 11.4182 10.3817C11.4182 10.2271 11.4182 10.0725 11.4182 9.91788C10.8716 9.78044 10.3485 9.68596 9.84093 9.52275C6.80343 8.52634 4.67953 5.52853 4.51555 2.0497C4.47651 1.19931 4.67172 0.958801 5.46038 1.01893C8.28704 1.24226 10.3875 2.74546 11.754 5.47699C11.8321 5.61443 11.8946 5.76904 11.957 5.90648ZM5.76491 2.3761C5.89765 5.41687 8.46664 8.35455 11.3402 8.51775C11.2074 5.33956 8.5213 2.52213 5.76491 2.3761ZM12.652 8.53493C16.0877 8.21711 18.2194 4.807 18.2272 2.39328C15.7441 2.2129 12.6832 5.56289 12.652 8.53493Z" fill="white" />
        <path d="M5.67904 13.8521C6.8425 13.0876 7.63896 13.1907 8.54474 14.1871C9.1538 14.8571 9.75506 15.5271 10.3641 16.1885C10.981 16.8671 11.3167 17.6745 11.3246 18.6451C11.3246 19.8305 11.3324 21.0245 11.3246 22.2099C11.3246 22.7682 11.1137 22.9915 10.614 22.9915C9.23189 22.9915 7.8576 22.9915 6.4755 22.9915C5.98357 22.9915 5.71808 22.751 5.77274 22.2013C5.84301 21.4712 5.58534 20.9816 5.11683 20.5005C4.15639 19.5127 3.23499 18.4819 2.3292 17.434C2.14961 17.2278 2.01687 16.8928 2.01687 16.6094C1.99344 13.9895 2.00125 11.3697 2.00125 8.74979C2.00125 8.08838 2.25893 7.83928 2.85237 7.908C4.539 8.12274 5.67123 9.52287 5.67904 11.3954C5.68685 12.1943 5.67904 12.9931 5.67904 13.8521ZM10.1455 21.6859C10.1455 20.5778 10.1533 19.5213 10.1377 18.4648C10.1299 17.9408 9.90342 17.5027 9.56765 17.1419C8.90393 16.4118 8.24802 15.6817 7.57649 14.9602C7.2173 14.5736 6.78003 14.5393 6.46769 14.8657C6.13193 15.2178 6.15535 15.6903 6.53016 16.1112C6.98305 16.618 7.45156 17.1248 7.91226 17.6316C8.24802 18.0009 8.27926 18.4046 8.01377 18.6795C7.74828 18.963 7.42813 18.92 7.09237 18.5507C6.33495 17.726 5.56972 16.91 4.83572 16.0511C4.66394 15.8449 4.53119 15.5099 4.52338 15.2264C4.49215 13.938 4.50777 12.6495 4.50777 11.3611C4.50777 10.7941 4.35941 10.2788 3.99241 9.87504C3.78158 9.64312 3.50047 9.47992 3.17252 9.23082C3.17252 11.7132 3.17252 14.0496 3.18033 16.3775C3.18033 16.5235 3.28965 16.6867 3.39116 16.7984C4.32036 17.8377 5.24957 18.8857 6.21001 19.8907C6.67071 20.3717 7.06894 20.8527 6.94401 21.6172C6.94401 21.6344 6.97524 21.6601 7.00648 21.7031C7.96692 21.7031 8.94298 21.7031 9.91903 21.7031C9.9815 21.7031 10.044 21.6945 10.1455 21.6859Z" fill="white" />
        <path d="M18.3209 13.8432C18.3209 12.9929 18.3209 12.194 18.3209 11.3952C18.3287 9.52262 19.4765 8.1225 21.1553 7.91634C21.7254 7.84762 21.9986 8.09673 21.9986 8.72378C21.9986 11.3608 22.0065 13.9893 21.983 16.6263C21.983 16.9012 21.8425 17.2448 21.6629 17.4423C20.6322 18.6191 19.578 19.7702 18.5161 20.9126C18.3052 21.1445 18.1959 21.3764 18.2272 21.7028C18.2428 21.8575 18.235 22.0207 18.2272 22.1753C18.2193 22.7766 18.0163 22.9999 17.4697 22.9999C16.1189 22.9999 14.7602 22.9999 13.4093 22.9999C12.8627 22.9999 12.6675 22.7851 12.6597 22.1667C12.6519 21.0243 12.6597 19.8732 12.6597 18.7308C12.6519 17.7344 12.9799 16.8926 13.6202 16.1882C14.2136 15.544 14.7992 14.8912 15.3849 14.247C16.3687 13.1904 17.1105 13.0702 18.3209 13.8432ZM17.0481 21.6771C17.1105 20.6463 17.1105 20.6463 17.7508 19.9419C18.68 18.9198 19.6014 17.889 20.5463 16.884C20.7493 16.6693 20.8352 16.4545 20.8352 16.1367C20.8196 14.4875 20.8274 12.8468 20.8274 11.1976C20.8274 10.5877 20.8274 9.96929 20.8274 9.368C20.7727 9.35941 20.7493 9.34224 20.7259 9.35082C20.6868 9.35941 20.6478 9.37659 20.6165 9.39377C19.8826 9.78031 19.5156 10.4331 19.4999 11.3007C19.4765 12.6149 19.5077 13.9377 19.4843 15.252C19.4765 15.5097 19.375 15.8275 19.211 16.0079C18.4614 16.884 17.6806 17.7172 16.8997 18.5676C16.5718 18.9284 16.236 18.9541 15.9783 18.6621C15.7284 18.3872 15.7675 18.0007 16.0876 17.6485C16.5561 17.1331 17.0325 16.6177 17.4932 16.0938C17.8524 15.69 17.868 15.2262 17.5478 14.874C17.2433 14.539 16.7904 14.5648 16.439 14.9427C15.7597 15.6729 15.0725 16.3944 14.4322 17.1675C14.1824 17.4681 13.9325 17.889 13.9091 18.2755C13.8388 19.4008 13.8856 20.526 13.8856 21.6771C14.932 21.6771 15.9783 21.6771 17.0481 21.6771Z" fill="white" />
    </svg>
    )
}