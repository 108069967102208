import EkinJIcon from "../../../assets/icons/sidebar-icons/ekin_j_icon";
import UsersIcon from "../../../assets/icons/sidebar-icons/users_icon";
import HosilIcon from "../../../assets/icons/sidebar-icons/hosil_icon-2";
import IshUrnIcon from "../../../assets/icons/sidebar-icons/ish_urn_icon";
import MeyorlarIcon from "../../../assets/icons/sidebar-icons/meyorlar_icon";
import MoliyaIcon from "../../../assets/icons/sidebar-icons/moliya_icon";
import ShartnomaIcon from "../../../assets/icons/sidebar-icons/shartnoma_icon";
import TaminotIcon from "../../../assets/icons/sidebar-icons/taminot_icon";
import TexnicsTIcon from "../../../assets/icons/sidebar-icons/texnics_t_icon";
import YerUIcon from "../../../assets/icons/sidebar-icons/yer_u_icon";

export const sidebar_Name = [
  {
    path: "/land-areas",
    name: "Yer uchastkalari",
    icon: <YerUIcon />,
  },
  {
    path: "/placement-of-crops",
    name: "Ekinlarni joylash",
    icon: <EkinJIcon />,
  },
  {
    path: "/shartnomalar",
    name: "Shartnomalar",
    icon: <ShartnomaIcon />,
    drop: [
      {
        path: "/shartnomalar/kontraktsiya",
        name: "Kontraktsiya g'alla",
      },
      {
        path: "/shartnomalar/fyuchers-paxta",
        name: "Fyuchers paxta",
      },
      {
        path: "/shartnomalar/sugurta",
        name: "Sug'urta",
      },
      {
        path: "/shartnomalar/imtiyozli-kridit",
        name: "Imtiyozli kredit",
      },
      {
        path: "/shartnomalar/lizing",
        name: "Lizing",
        drop: [
          {
            path: "/shartnomalar/lizing/technics-list",
            name: "Texnika ro'yxati",
          },
          {
            path: "/shartnomalar/lizing/leasing-contract",
            name: "Lizing shartnoma",
          },
          {
            path: "/shartnomalar/lizing/warranty-contract",
            name: "Kafillik shartnoma",
          },
          {
            path: "/shartnomalar/lizing/acts",
            name: "Dalolatnoma",
          },
          {
            path: "/shartnomalar/lizing/insurance-contract",
            name: "Sug'urta shartnoma",
          },
          {
            path: "/shartnomalar/lizing/debt-transfer",
            name: "Qarz ko'chirish",
          },
        ],
      },
      {
        path: "/shartnomalar/taminot-shartnomasi",
        name: "Ta'minot shartnomasi",
      },
    ],
  },
  {
    path: "/moliyalashtirish",
    name: "Moliyalashtirish",
    icon: <MoliyaIcon />,
  },
  {
    path: "/taminot-xizmat",
    name: "Ta'minot-xizmat",
    icon: <TaminotIcon />,
  },
  {
    path: "/agrotexnik-tadbirlar",
    name: "Agrotexnik tadbirlar",
    icon: <TexnicsTIcon />,
  },
  {
    path: "/hosil-qabuli",
    name: "Hosil qabuli",
    icon: <HosilIcon />,
  },
  {
    path: "/fermer-ish-urni",
    name: "Fermer ish o'rni",
    icon: <IshUrnIcon />,
  },
  {
    path: "/meyorlar",
    name: "Meyorlar",
    icon: <MeyorlarIcon />,
  },
  {
    path: "/users",
    name: "Foydalanuvchilar",
    icon: <UsersIcon />,
  },
];
