
import {createAction,createReducer} from "@reduxjs/toolkit";

export const editTitleName = createAction('ADD_TITLE_NAME')
export const editSidebarIsOpen = createAction('EDIT_SIDEBAR_OPEN')
export const editGamburgerIsOpen = createAction('EDIT_GAMBURGER_OPEN')

export default createReducer({
    navTitle: {
        title: 'Texnikalar'
    }, 
    sidebarIsOpen: false,
    gamburgerIsOpen: false
},{
    ADD_TITLE_NAME:(state,action)=>{
        state.navTitle={
            title: action.payload
        }
    },
    EDIT_SIDEBAR_OPEN: (state,action)=>{
        state.sidebarIsOpen= action.payload
    },
    EDIT_GAMBURGER_OPEN: (state,action)=>{
        state.gamburgerIsOpen= action.payload
    }
})