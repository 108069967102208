import axios from "axios";
import { baseUrl } from "./baseUrl";
// const GlobalUrl = "https://agro-api-drf.herokuapp.com"
// const GlobalUrl = "http://192.168.42.159:8000"
const GlobalUrl = baseUrl
//https://agro-api-drf.herokuapp.com
export function doGet(url) {
    return axios.get(GlobalUrl + url)
        .then((res) => {
            return res.data;
        })
}
export const doDelete = async (url, data) => {
    return await axios.delete(GlobalUrl + url, data)
        .then((res) => {
            return res.data;
        })
}
export const doPost = async (url, data) => {
    return await axios.post(GlobalUrl + url, data)
        .then((res) => {
            return res.data;
            
        })
}
export const doGetPayment = async (url, setState) =>{
    setState(prev => {
        return{
            ...prev,
            loading: true
        } 
    })
    return await axios.get(GlobalUrl + url)
        .then((res) => {
           setState(prev =>{
               return {
                   ...prev,
                   loading: false,
                   success: true,
                   error : false,
                   data : res?.data
               }
           })
        }).catch((e)=>{
            setState(prev =>{
                return {
                    ...prev,
                    loading: false,
                    success: false,
                    error : true,
                    data : e?.response
                }
            })
        })
}