import {configureStore} from "@reduxjs/toolkit";
import technicsListReduser from "./technicsListReduser";
import selectTypeRedux from "./selectTypeRedux";
import navTitleReduser from "./navTitleReduser";

export default configureStore({
    reducer:{
        technics: technicsListReduser ,
        selectName: selectTypeRedux, 
        navTitle: navTitleReduser,
        sidebarIsOpen: navTitleReduser,
        gamburgerIsOpen: navTitleReduser,
    }
})