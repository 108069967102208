import React from 'react'

export default function ekin_j_icon() {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M13.4424 21.9445C13.668 21.9445 13.8131 21.9445 13.942 21.9445C16.3433 21.9445 18.7284 21.9445 21.1297 21.9445C21.2425 21.9445 21.3715 21.9266 21.4843 21.9625C21.8066 22.0162 22 22.2493 22 22.6078C22 22.9664 21.7905 23.1994 21.4682 23.2532C21.3554 23.2711 21.2264 23.2532 21.1136 23.2532C15.0379 23.2532 8.94601 23.2532 2.87027 23.2532C2.77357 23.2532 2.69299 23.2532 2.59629 23.2532C2.24174 23.2173 2 22.9484 2 22.5899C2 22.2314 2.24174 21.9625 2.61241 21.9445C3.16035 21.9266 3.7083 21.9445 4.25624 21.9445C6.3191 21.9445 8.39807 21.9445 10.5576 21.9445C10.4126 21.7653 10.332 21.6398 10.2353 21.5322C8.38195 19.2735 6.722 16.8713 5.57776 14.0747C5.06205 12.8199 4.69138 11.5112 4.56245 10.1309C4.1112 5.75679 7.09267 1.56196 11.0411 1.06002C15.1346 0.540145 18.664 3.44426 19.4053 7.96177C19.7115 9.87992 19.3409 11.7084 18.6801 13.4652C17.6164 16.3335 15.9726 18.7894 14.1354 21.102C13.9098 21.3709 13.7002 21.6039 13.4424 21.9445ZM11.9919 21.7115C12.3626 21.2633 12.6849 20.9048 12.9911 20.5283C14.78 18.3234 16.4239 15.975 17.5036 13.2143C18.0838 11.7084 18.4706 10.1667 18.2772 8.49957C17.9388 5.39826 15.7792 2.87061 13.0556 2.38659C10.2192 1.88464 7.47945 3.48011 6.27075 6.3663C5.4166 8.40993 5.56164 10.4715 6.25463 12.5331C7.09267 15.0428 8.44641 17.194 9.97744 19.2197C10.6221 20.0443 11.299 20.851 11.9919 21.7115Z" fill="white"/>
    <path d="M10.2031 9.46762C10.2031 8.64299 10.1386 7.80044 10.2031 6.95789C10.2998 5.82851 10.7833 4.8784 11.4924 4.05378C11.8308 3.65939 12.1531 3.65939 12.4916 4.0717C13.668 5.48791 14.0709 7.15508 13.7486 9.03738C13.7325 9.16287 13.7003 9.28835 13.668 9.41384C13.668 9.43176 13.668 9.43176 13.668 9.43176C14.2482 9.21665 14.8123 8.96567 15.3925 8.76848C15.6664 8.67885 15.9565 8.66092 16.2466 8.64299C16.7301 8.62507 16.9718 8.89397 16.9718 9.43176C16.9557 12.0491 15.0379 14.4512 12.7011 14.7739C9.73574 15.1862 7.23775 12.8916 7.01213 9.5931C6.96378 8.87604 7.23775 8.58921 7.89851 8.64299C8.75266 8.75055 9.55846 9.03738 10.2031 9.46762ZM11.992 5.50583C10.9444 7.0296 11.2184 9.39591 12.0403 10.2564C12.9267 8.87604 12.9267 6.72484 11.992 5.50583ZM12.685 13.4653C14.5705 13.1426 15.747 11.0989 15.6826 10.0592C14.1193 10.4177 12.7978 11.8877 12.685 13.4653ZM8.26918 10.0771C8.55927 11.8519 10.0742 13.5011 11.3312 13.4294C11.1056 11.7622 9.76797 10.2922 8.26918 10.0771Z" fill="white"/>
    </svg>
  )
}

