import Sun from '../../../assets/icons/nav-weather/sunrise-light.svg'
import Moon from '../../../assets/icons/nav-weather/moon-light.svg'
import mostly_cloudy_light from '../../../assets/icons/nav-weather/mostly-cloudy-light.svg'
import Rain from '../../../assets/icons/nav-weather/rain-light.svg'
import Rain_night from '../../../assets/icons/nav-weather/rainy-night-light.svg'
import Rain_day from '../../../assets/icons/nav-weather/rainyday-light.svg'
import thunderstorm from '../../../assets/icons/nav-weather/thunderstorm.svg'
import Snow from '../../../assets/icons/nav-weather/snow-light.svg'
import Mist from '../../../assets/icons/nav-weather/mist.svg'

import React, { useState } from 'react';

function IconsImg({ navBackInfo }) {
    const [hours] = useState(new Date().getHours())
    return (
        <span>
            {
                hours >= 18 && hours <= 6 ?
                    <img className='personalImg' src={
                        navBackInfo?.data?.weather?.status === 'Clear' ? Moon
                            : navBackInfo?.data?.weather?.status === "Clouds" ? mostly_cloudy_light
                                : navBackInfo?.data?.weather?.status === "Drizzle" ? Rain
                                    : navBackInfo?.data?.weather?.status === "Rain" ? Rain_night
                                        : navBackInfo?.data?.weather?.status === 'Thunderstorm' ? thunderstorm
                                            : navBackInfo?.data?.weather?.status === 'Snow' ? Snow
                                                : Mist
                    } alt={navBackInfo?.data?.weather?.status} />
                    : <img className='personalImg' src={
                        navBackInfo?.data?.weather?.status === 'Clear' ? Sun
                            : navBackInfo?.data?.weather?.status === "Clouds" ? mostly_cloudy_light
                                : navBackInfo?.data?.weather?.status === "Drizzle" ? Rain
                                    : navBackInfo?.data?.weather?.status === "Rain" ? Rain_day
                                        : navBackInfo?.data?.weather?.status === 'Thunderstorm' ? thunderstorm
                                            : navBackInfo?.data?.weather?.status === 'Snow' ? Snow
                                                : Mist
                    } alt={navBackInfo?.data?.weather?.status} />

            }
        </span>
    );
}

export default IconsImg;
