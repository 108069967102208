import { AnimatePresence, motion } from "framer-motion";
import React from "react";
import { FaAngleDown } from "react-icons/fa";
import { NavLink } from "react-router-dom";

export const DropMenu = ({
  isOpen,
  showAnimation,
  route,
  dropMenu,
  openDropLizing,
  setOpenDropLizing,
  toggle
}) => {

 
  const toggleMenu = () => {
    setOpenDropLizing(!openDropLizing);
  };
  const menuAnimation = {
    hidden: {
      height: 0,
      opacity: 0,
      transition: {
        duration: 0.2,
        when: "afterChildren",
      },
    },
    show: {
      height: "auto",
      opacity: 1,
      transition: {
        duration: 0.3,
        when: "beforeChildren",
      },
    },
  };
  const menuItemAnimation = {
    hidden: {
      padding: 0,
      transition: {
        duration: 0.1,
      },
    },
    show: {
      transition: {
        duration: 0.3,
      },
    },
  };
 
  return (
    <>
      <div
        className={`${openDropLizing ? "drop-menu-lizing  actives" : "drop-menu-lizing "}  `}
        onClick={toggleMenu}
      >
        <div className="drop-menu-item">
          <AnimatePresence>
            {isOpen && (
              <motion.div
                variants={showAnimation}
                initial="hidden"
                animate="show"
                exit={"hidden"}
                className="link_text1"
              >
                {route?.name}
              </motion.div>
            )}
          </AnimatePresence>
        </div>
        {isOpen && (
          
          <div>
            <FaAngleDown
              className={openDropLizing ? "openDropIcon" : "closeDropIcon"}
            />
          </div>
        )}
        
      </div>
      <AnimatePresence>
        {openDropLizing && (
          <motion.div
            variants={menuAnimation}
            initial="hidden"
            animate="show"
            exit={"hidden"}
            className="drop-menu-container "
          >
            {dropMenu?.map((item, index) => {
              
              return (
                <motion.div
                  variants={menuItemAnimation}
                  key={index}
                >
                  <NavLink
                    to={item.path}
                    activeClassName="actives"
                    className="sidebar-link sidebar-link-padding"
                    // onClick={toggle}
                  >
                    <AnimatePresence>
                      {isOpen && (
                        <motion.div
                          variants={showAnimation}
                          initial="hidden"
                          animate="show"
                          exit={"hidden"}
                          className="link_text"
                        >
                          {item?.name}
                        </motion.div>
                      )}
                    </AnimatePresence>
                    
                  </NavLink>
                </motion.div>
              );
            })}
          </motion.div>
        )}
      </AnimatePresence>
    </>
  );
};
