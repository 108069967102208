import React from 'react';
import styled from 'styled-components';
import Mail_support_icon from '../../../assets/icons/sidebar-icons/mail_support_icon';
function TechnixsHelp({isOpen}) {
    return (
        <ContainerCard>
            <SupportCardIcons>
                <div><Mail_support_icon /> </div>
               {isOpen && <div className='text-sup'>Yordam kerakmi?</div>}
            </SupportCardIcons>
            {isOpen && <ButtonStyle>
                Texnik qo'llab quvvatlash
            </ButtonStyle>}
        </ContainerCard>
    );
}

export default TechnixsHelp;

const ContainerCard = styled.div`
    width: 100%;
    height: 18vh;
    padding: 20px;
    background: #4A926F;
    display: flex;
    flex-direction: column;
    @media (max-width : 1400px){
        padding: 18px;
    }
`
const SupportCardIcons = styled.div`
    display: flex;
    gap: 12px;
    align-items: center;
    & .text-sup{
        color: #fff;
        font-size: 16px;
    }
    @media (max-width : 1400px){
        gap: 10px;
        & .text-sup{
            font-size: 14px;
        }
    }
`
const ButtonStyle = styled.button`
    background: #FFFFFF;
    border-radius: 4px;
    color: #3C8C6F;
    font-weight: 500;
    font-size: 12px;
    border: none;
    padding: 8px 18px;
    margin-top: 10px
`