import React from 'react'

export default function users_icon() {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M14.1404 20.6339C12.133 20.6339 10.1252 20.6339 8.11716 20.6339C7.38431 20.6339 7.26945 20.4944 7.29698 19.665C7.41943 15.9436 10.1952 12.7399 13.4674 12.4356C17.2854 12.0806 20.3079 15.0381 20.8889 18.6359C20.9487 19.0169 20.9849 19.402 20.9971 19.7886C21.0227 20.4712 20.8804 20.6339 20.2567 20.6339C18.4863 20.6392 16.7158 20.6339 14.9407 20.6339H14.1404Z" fill="white"/>
    <path d="M14.169 11.2216C13.1925 11.2298 12.2529 10.8071 11.5557 10.0462C10.8585 9.2852 10.4605 8.24783 10.4487 7.16103C10.4429 6.61833 10.5337 6.07973 10.7159 5.57629C10.898 5.07284 11.1679 4.6145 11.5099 4.2277C11.852 3.84089 12.2594 3.53327 12.7088 3.32258C13.1582 3.11188 13.6405 3.00228 14.1281 3.00009C15.1048 2.99303 16.0442 3.41646 16.7413 4.17788C17.4384 4.9393 17.8364 5.97683 17.8484 7.06382C17.8543 7.60641 17.7636 8.14492 17.5814 8.64825C17.3993 9.15159 17.1294 9.60977 16.7873 9.99635C16.4452 10.3829 16.0377 10.6903 15.5883 10.9005C15.1389 11.1108 14.6565 11.22 14.169 11.2216V11.2216Z" fill="white"/>
    <path d="M6.82718 7.69778C7.53163 7.70086 8.20618 8.01508 8.70262 8.57141C9.19905 9.12773 9.47677 9.88066 9.47475 10.6648C9.4624 11.4396 9.17876 12.1784 8.68424 12.7239C8.18971 13.2694 7.52339 13.5785 6.82718 13.5852C6.12256 13.5808 5.44837 13.2651 4.9528 12.7075C4.45722 12.15 4.18083 11.3962 4.18436 10.6119C4.19332 9.8374 4.47542 9.09782 4.96981 8.55267C5.4642 8.00753 6.13127 7.70049 6.82718 7.69778V7.69778Z" fill="white"/>
    <path d="M7.70714 14.9155C6.65343 16.6441 6.12183 18.4985 6.26327 20.6339C5.61491 20.6339 4.99028 20.6339 4.36469 20.6339C3.77993 20.6339 3.19517 20.6339 2.61136 20.6339C2.23924 20.6339 1.99811 20.4405 2.00001 19.9999C2.0152 16.4571 5.0871 14.1632 7.70714 14.9155Z" fill="white"/>
    </svg>    
  )
}
