import React, { useState, useEffect } from 'react';
import personalImg from '../../assets/images/nav/kabiniet.png'
import strelkaRed from '../../assets/images/nav/strelkaRed.png';
import strelkaGreen from '../../assets/images/nav/strelkaGreen.png';
import UzLogo from '../../assets/images/auth/uzbekistan 1.png'
import RuLogo from '../../assets/images/auth/rusLogo.svg'
import 'antd/dist/antd.min.css';
import { doGetPayment } from '../../redux/services/services'
import './style.css'
import { Select } from 'antd';
import IconsImg from './component/icons-img';
import DropDown from './component/DropDown';
import { useSelector, useDispatch } from 'react-redux';
import { FaBars } from 'react-icons/fa';
import { editGamburgerIsOpen, editSidebarIsOpen } from 'redux/reducer/navTitleReduser';


function Navbar() {
    const navTitle = useSelector(state => state.navTitle.navTitle)
    const sidebarIsOpen = useSelector(state => state.sidebarIsOpen.sidebarIsOpen)
    const gamburgerIsOpen = useSelector(state => state.gamburgerIsOpen.gamburgerIsOpen)
    const [clickNavBtn] = useState(true)
    const dispatch = useDispatch()
    const [navBackInfo, setNavBackInfo] = useState({ loading: false, error: false, success: false, data: null })
    const { Option } = Select;

    function handleChange(value) {
        console.log(`selected ${value}`);
    }

    async function getWeather() {
        try {
            await doGetPayment('/tool/currency-weather/', setNavBackInfo)
        } catch (e) {
            console.log(e);
        }
    }
    function toggle() {
        dispatch(editSidebarIsOpen(!sidebarIsOpen))
    }
    function resizeListener() {
        if (window.innerWidth <= 576) {
            dispatch(editGamburgerIsOpen(true))
        } else if (window.innerWidth > 576) dispatch(editGamburgerIsOpen(false))

    }

    window.addEventListener("resize", resizeListener);
 
    useEffect(() => {
        getWeather()
        resizeListener()
    }, [])
    console.log('gamburger', gamburgerIsOpen)

    return (
        <div className='NavbarNav'>
            <div className='NavBG'>
                <div className='NavBGC'>
                    <div className='nav-left'>
                        {
                            gamburgerIsOpen && <span onClick={toggle} style={{ cursor: 'pointer', marginRight: '5px' }}>
                            <FaBars style={{ color: "#fff", fontSize: '20px' }} />
                        </span>}
                        {!gamburgerIsOpen && <h3>{navTitle.title}</h3>}
                        {/* <p>Bosh sahifa {' > '}Lising</p> */}
                    </div>
                    <div className='nav-right'>
                        {!gamburgerIsOpen && <span className='vertical'></span>}
                        {
                           !gamburgerIsOpen && <span className='valuta-card'>
                            <span >
                                <span className='davlat-nomi'>EUR</span>
                                <img src={
                                    navBackInfo?.data?.eur_uzs?.status === 1 ? strelkaGreen : strelkaRed
                                } alt={'strelka'} />
                                <p>{navBackInfo.data ? navBackInfo?.data?.eur_uzs?.price : 0} €</p>
                            </span>
                            <span className='card-group-center'>
                                <span className='davlat-nomi'>USD</span>
                                <img src={navBackInfo?.data?.usd_uzs?.status === 1 ? strelkaGreen : strelkaRed} alt={'strelka'} />
                                <p>{navBackInfo.data ? navBackInfo?.data?.usd_uzs?.price : 0} $</p>
                            </span>
                            <span>
                                <span className='davlat-nomi'>RUB</span>
                                <img src={navBackInfo?.data?.rub_uzs?.status === 1 ? strelkaGreen : strelkaRed} alt={'strelka'} />
                                <p>{navBackInfo.data ? navBackInfo?.data?.rub_uzs?.price : 0} ₽</p>
                            </span>

                        </span>
                        }
                        {!gamburgerIsOpen && <span className='vertical'></span>}
                        {!gamburgerIsOpen && <span className='cloudInfo'>
                            <IconsImg navBackInfo={navBackInfo} />
                            <span className='cloud'>
                                <p>Toshknet. sh.</p>
                                <p>{navBackInfo?.data?.weather?.temp}° C</p>
                            </span>
                        </span>}
                        <span className='vertical'></span>
                        <span>
                            <Select className='select-location-nav' defaultValue="uz" style={{ minWidth: 110 }} onChange={handleChange}>
                                <Option value="uz"><img src={UzLogo} alt="flag" /> <span>Uzbek</span></Option>
                                <Option value="ru"><img src={RuLogo} alt="flag" /> Russian</Option>
                                <Option value="узб"><img src={UzLogo} alt="flag" /> Ўзбек</Option>
                            </Select>
                        </span>
                        <span className='vertical'></span>
                        <span style={{ display: "flex", alignItems: "center" }} >
                            <img className={'personalImg'} src={personalImg} alt="personalImg" />
                            <DropDown />
                        </span>
                    </div>

                    <div className={clickNavBtn ? "scrollNav scrollNav-None " : "scrollNav"} >
                        {/* <marquee direction="right" className="marquee-class"> */}
                        <div className='scroll-nav-menu'>
                            <p>
                                No’xot <img src={strelkaRed} alt="pastga" />
                            </p>
                            <p>15 000 sum</p>
                        </div>
                        <div className='scroll-nav-menu'>
                            <p>
                                No’xot <img src={strelkaRed} alt="pastga" />
                            </p>
                            <p>15 000 sum</p>
                        </div>
                        <div className='scroll-nav-menu'>
                            <p>
                                No’xot <img src={strelkaRed} alt="pastga" />
                            </p>
                            <p>15 000 sum</p>
                        </div>
                        <div className='scroll-nav-menu'>
                            <p>
                                No’xot <img src={strelkaRed} alt="pastga" />
                            </p>
                            <p>15 000 sum</p>
                        </div>
                        <div className='scroll-nav-menu'>
                            <p>
                                No’xot <img src={strelkaRed} alt="pastga" />
                            </p>
                            <p>15 000 sum</p>
                        </div>
                        <div className='scroll-nav-menu'>
                            <p>
                                No’xot <img src={strelkaRed} alt="pastga" />
                            </p>
                            <p>15 000 sum</p>
                        </div>
                        <div className='scroll-nav-menu'>
                            <p>
                                No’xot <img src={strelkaRed} alt="pastga" />
                            </p>
                            <p>15 000 sum</p>
                        </div>
                        <div className='scroll-nav-menu'>
                            <p>
                                No’xot <img src={strelkaRed} alt="pastga" />
                            </p>
                            <p>15 000 sum</p>
                        </div>
                        <div className='scroll-nav-menu'>
                            <p>
                                No’xot <img src={strelkaRed} alt="pastga" />
                            </p>
                            <p>15 000 sum</p>
                        </div>
                        <div className='scroll-nav-menu'>
                            <p>
                                No’xot <img src={strelkaRed} alt="pastga" />
                            </p>
                            <p>15 000 sum</p>
                        </div>
                        <div className='scroll-nav-menu'>
                            <p>
                                No’xot <img src={strelkaRed} alt="pastga" />
                            </p>
                            <p>15 000 sum</p>
                        </div>
                        <div className='scroll-nav-menu'>
                            <p>
                                No’xot <img src={strelkaRed} alt="pastga" />
                            </p>
                            <p>15 000 sum</p>
                        </div>
                        <div className='scroll-nav-menu'>
                            <p>
                                No’xot <img src={strelkaRed} alt="pastga" />
                            </p>
                            <p>15 000 sum</p>
                        </div>
                        <div className='scroll-nav-menu'>
                            <p>
                                No’xot <img src={strelkaRed} alt="pastga" />
                            </p>
                            <p>15 000 sum</p>
                        </div>
                        <div className='scroll-nav-menu'>
                            <p>
                                No’xot <img src={strelkaRed} alt="pastga" />
                            </p>
                            <p>15 000 sum</p>
                        </div>
                        <div className='scroll-nav-menu'>
                            <p>
                                No’xot <img src={strelkaRed} alt="pastga" />
                            </p>
                            <p>15 000 sum</p>
                        </div>
                        <div className='scroll-nav-menu ' style={{ marginRight: "0" }}>
                            <p>
                                No’xot <img src={strelkaRed} alt="pastga" />
                            </p>
                            <p>15 000 sum</p>
                        </div>
                        {/* </marquee> */}
                    </div>


                </div>
            </div>

        </div>
    );
}

export default Navbar;