import React from 'react'

export default function Mail_support_icon() {
    return (
        <svg width="36" height="36" viewBox="0 0 36 36" fill="none" xmlns="http://www.w3.org/2000/svg">
            <rect width="36" height="36" rx="18" fill="white" />
            <path d="M9 14L16.89 19.26C17.2187 19.4793 17.6049 19.5963 18 19.5963C18.3951 19.5963 18.7813 19.4793 19.11 19.26L27 14M11 25H25C25.5304 25 26.0391 24.7893 26.4142 24.4142C26.7893 24.0391 27 23.5304 27 23V13C27 12.4696 26.7893 11.9609 26.4142 11.5858C26.0391 11.2107 25.5304 11 25 11H11C10.4696 11 9.96086 11.2107 9.58579 11.5858C9.21071 11.9609 9 12.4696 9 13V23C9 23.5304 9.21071 24.0391 9.58579 24.4142C9.96086 24.7893 10.4696 25 11 25Z" stroke="#4A926F" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
        </svg>
    )
}

