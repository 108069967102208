// import './App.css';
import { BrowserRouter as Router } from "react-router-dom";
// import { SystemContextProvider } from "./context";
import Routes from './routes/route'
import { ErrorBoundary } from 'react-error-boundary'
import { ErrorProvider } from "./components/styles/errorStyle";

function App() {
  function ErrorFallback({ error, resetErrorBoundary }) {
    return (
      <div role="alert">
        <ErrorProvider>
          <div className="card">
            <i className="fa-solid fa-triangle-exclamation fa-3x"></i>
            <p>Something went wrong:</p>
            <pre>{error.message}</pre>
            <button onClick={resetErrorBoundary}>
              Try again
            </button>
          </div>
        </ErrorProvider>
      </div>
    )
  }
  return (
    <Router>
      <ErrorBoundary FallbackComponent={ErrorFallback}>
        <Routes />
      </ErrorBoundary>
    </Router>

  );
}

export default App;
