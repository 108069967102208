import React from 'react';
import 'antd/dist/antd.min.css';
import { Menu, Dropdown, Button } from 'antd';
import Profile from 'assets/images/nav/Profile.svg'
import Question from 'assets/images/nav/Question.svg'
import Grafics from 'assets/images/nav/grafics.svg'
import Logout from 'assets/images/nav/Logout.svg'
import Stroke from 'assets/images/nav/Stroke 1.png'

const styles = {
    display : "flex",
    alignItems: "center",
    color: "#313131",
    fontWeight: 500,
}

const menu = (
    <Menu>
        <Menu.Item>
            <a className='dropdown-nav' rel="nofollow"  style={styles}>
                <img src={Profile} alt={"ProfilImg"} style={{marginRight:"8px"}}/>
                Shaxsiy kabinet
            </a>
        </Menu.Item>
        <Menu.Item>
            <a className='dropdown-nav' rel="nofollow"  style={styles}>
                <img src={Question} alt={"question"} style={{marginRight:"8px"}}/>
                Ko’p beriladiga savollar
            </a>
        </Menu.Item>
        <Menu.Item>
            <a className='dropdown-nav' rel="nofollow"  style={styles}>
                <img src={Grafics} alt={'Grafics'} style={{marginRight:"8px"}}/>
                UZEX kabinetiga o’tish
            </a>
        </Menu.Item>
        <Menu.Item>
            <a className='dropdown-nav' rel="nofollow"  style={{...styles, color:"red"}}>
                <img src={Logout} alt={'logout'} style={{marginRight:"8px"}}/>
                Chiqish
            </a>
        </Menu.Item>
    </Menu>
);

const DropDown = () => {
    return (
        <Dropdown overlay={menu} placement="bottomRight" trigger={['click']}>
            <Button>Azimov N.  <img src={Stroke} className='strokeActive'/></Button>
        </Dropdown>
    );
};

export default DropDown;