import { AnimatePresence, motion } from "framer-motion";
import React, { Fragment, useEffect, useState } from "react";
import { FaAngleDown, FaAngleRight } from "react-icons/fa";
import { NavLink, useHistory } from "react-router-dom";
import { DropMenu } from "./dropMenu";

export const SidebarDropMenu = ({
  isOpen,
  showAnimation,
  route,
  dropMenu,
  isOpenMenu,
  setIsOpenMenu,
  setIsOpen,
  toggle
}) => {
  const history = useHistory();

  const [pathName, setPathName] = useState(history.location.pathname);
  const [openDropLizing, setOpenDropLizing] = useState(false);
  history.listen(() => {
    if (history.location.pathname) setPathName(history.location.pathname);
  });
  const toggleMenu = () => {
    setIsOpenMenu(!isOpenMenu);
    setIsOpen(true);
  };
  const menuAnimation = {
    hidden: {
      height: 0,
      opacity: 0,
      transition: {
        duration: 0.2,
        when: "afterChildren",
      },
    },
    show: {
      height: "auto",
      opacity: 1,
      transition: {
        duration: 0.3,
        when: "beforeChildren",
      },
    },
  };
  const menuItemAnimation = {
    hidden: {
      padding: 0,
      transition: {
        duration: 0.1,
      },
    },
    show: {
      transition: {
        duration: 0.3,
      },
    },
  };
  function PathLocation() {
    history.location.pathname.includes('/shartnomalar/lizing') && setIsOpenMenu(true);
  }
  useEffect(() => {
    PathLocation();
  }, [pathName]);
  return (
    <>
      <div
        className={`${isOpenMenu ? "drop-menu  actives" : "drop-menu "}  `}
        onClick={toggleMenu}
        // className={""}
      >
        <div className="drop-menu-item">
          <div className="icon">{route?.icon}</div>

          <AnimatePresence>
            {isOpen && (
              <motion.div
                variants={showAnimation}
                initial="hidden"
                animate="show"
                exit={"hidden"}
                className="link_text"
              >
                {route?.name}
              </motion.div>
            )}
          </AnimatePresence>
        </div>
        {isOpen && (
          
          <div>
            <FaAngleDown
              className={isOpenMenu ? "openDropIcon" : "closeDropIcon"}
            />
          </div>
        )}
        
      </div>
      <AnimatePresence>
        {isOpenMenu && (
          <motion.div
            variants={menuAnimation}
            initial="hidden"
            animate="show"
            exit={"hidden"}
            className="drop-menu-container "
          >
            {dropMenu?.map((item, index) => {
              if (item?.drop) {
                return (
                    <Fragment key={index}>
                        <DropMenu isOpen={isOpen} showAnimation={showAnimation} route={item}
                            dropMenu={item.drop}
                            openDropLizing={openDropLizing}
                            setOpenDropLizing={setOpenDropLizing}
                            setIsOpen={setIsOpen}
                            toggle={toggle}
                        />
                    </Fragment>
                )
            }
              return (
                <motion.div
                  variants={menuItemAnimation}
                  key={index}
                >
                  <NavLink
                    to={item.path}
                    activeClassName="actives"
                    className="sidebar-link"
                    style={{ display: "flex" }}
                    // onClick={toggle}
                  >
                    <AnimatePresence>
                      {isOpen && (
                        <motion.div
                          variants={showAnimation}
                          initial="hidden"
                          animate="show"
                          exit={"hidden"}
                          className="link_text"
                        >
                          {item?.name}
                        </motion.div>
                      )}
                    </AnimatePresence>
                    {item.name === "Lizing" && (
                      <FaAngleRight className={"dropIcon"} />
                    )}
                  </NavLink>
                </motion.div>
              );
            })}
          </motion.div>
        )}
      </AnimatePresence>
    </>
  );
};
