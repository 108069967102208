import { systemColors } from "components/colors";
import styled from "styled-components";

export const ErrorProvider = styled.div`
  display: flex !important;
  align-items: center !important;
  justify-content: center !important;
  flex-direction: column;
  width: 100% !important;
  margin: auto !important;
  height: 100vh;
  background: ${systemColors.mainGreen};
  & .card {
    width: 400px;
    height: 300px;
    background: white;
    border-radius: 12px;
    box-shadow: 2px 0px 12px rgba(0, 0, 0, 0.1);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    color: #c5192d;
    & i {
      margin-bottom: 20px;
    }
    & p {
      font-size: 24px;
    }
    & pre {
      white-space: pre-wrap !important;
      box-sizing: border-box !important;
      word-break: break-word !important;
      &::-webkit-scrollbar {
        display: none !important;
      }
    }
    
    button {
      border: none;
      outline: none;
      padding: 7px 24px;
      background: rgba(0, 66, 105, 0.1);
      font-family: "Inter", sans-serif;
      border-radius: 4px;
      cursor: pointer;
      transition: all 0.3s;
      &:hover {
        background: ${systemColors.mainGreen};
        color: white;
      }
    }
  }
`;

export const ErrorTableList = styled.td`
color: red;
margin-left: 5px;
`