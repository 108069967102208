import {createAction,createReducer} from "@reduxjs/toolkit";

export const selectTypeAction = createAction('SELECT_TYPE')

export default createReducer({
    selectName: { loading: false, error: false, success: false, data: null }
},{
    SELECT_TYPE:(state,action)=>{
        state.selectName={...action.payload}
    }
})