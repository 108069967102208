import React, { Suspense, useState, useEffect } from 'react';
// @ts-ignore
import { Route, Switch, useHistory } from 'react-router-dom';
// custom Components import
// routes
import { routes } from './routes.path';
import Sidebar from '../components/sideber/Sidebar';
import Navbar from '../components/navbar/Navbar';
import ReactLoader from 'components/react-loader/ReactLoader';

const Routes = () => {
  const history = useHistory();
  const [pathName, setPathName] = useState(history.location.pathname);

  history.listen(() => {
    if (history.location.pathname) setPathName(history.location.pathname);
  });
  function PathLocation() {
    ['/'].includes(pathName) &&
      history.push('/shartnomalar/lizing/technics-list');
  }
  useEffect(() => {
    PathLocation();
  }, [pathName]);
  return (
    <>
      <Suspense
        fallback={
            <ReactLoader/>
        }
      >
        <div className="d-flex">
          {![
            '/shartnomalar/lizing/agreements-sign',
            '/shartnomalar/lizing/orders/leasing-orders-tosign',
            '/shartnomalar/lizing/leasing-contract/leasing-contracts-to-sign',
            '/shartnomalar/lizing/acts/leasing-acts-tosign',
            '/warrantyagreements-tosign',
          ].includes(pathName) && <Sidebar />}

          <div
            style={{
              width: ![
                '/shartnomalar/lizing/leasing-contract/leasing-contracts-to-sign',
                '/shartnomalar/lizing/acts/leasing-acts-tosign',
                '/warrantyagreements-tosign',
                '/shartnomalar/lizing/orders/leasing-orders-tosign',
              ].includes(pathName)
                ? '100%'
                : '100%',
              height: '100vh',
              overflowY:
                ![
                  '/shartnomalar/lizing/leasing-contract/leasing-contracts-to-sign',
                  '/shartnomalar/lizing/acts/leasing-acts-tosign',
                  '/warrantyagreements-tosign',
                  '/shartnomalar/lizing/orders/leasing-orders-tosign',
                ].includes(pathName) && 'scroll',
            }}
          >
            {![
              '/shartnomalar/lizing/agreements-sign',
              '/shartnomalar/lizing/leasing-contract/leasing-contracts-to-sign',
              '/shartnomalar/lizing/acts/leasing-acts-tosign',
              '/warrantyagreements-tosign',
              '/shartnomalar/lizing/orders/leasing-orders-tosign',
              // '/shartnomalar/lizing/agreements-sign',
            ].includes(pathName) && <Navbar />}
            <Switch>
              {routes.map((route, index) => {
                return <Route key={index} exact {...route} />;
              })}
            </Switch>
          </div>
        </div>
      </Suspense>
    </>
  );
};

export default Routes;
