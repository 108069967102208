
import {createAction,createReducer} from "@reduxjs/toolkit";

export const addTechnicsName = createAction('ADD_TECHNICS_NAME')
// export const deleteExpense = createAction('DELETE_EXPENSE')
// export function editExpanseName(name,id){
//     return{
//         type: 'EDIT_EXPANSE_NAME',
//         payload: name,
//         Id: id
//     }
// }

export default createReducer({
    technics: {}
},{
    ADD_TECHNICS_NAME:(state,action)=>{
        state.technics={...action.payload}
    }
})